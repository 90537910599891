<template>
  <FilterSelect
  :disableDropdown="disableDropdown"
  :options="tenantOptions"
  :selectedOption="tenant.display_name"
  @select-changed="(t) => changeTenant(t)"
  >
    <template #secondary-action>
      <v-tooltip
          location="top">
          <template
            #activator="{ props }">
            <v-btn
              v-bind="props"
              :loading="tenantUpdateRequested"
              class="ma-0"
              variant="text"
              icon
              size="25"
              @click="updateDefaultTenant">
              <v-icon
                class="favorite-icon"
                :icon="isTenantDefault ? 'mdi-star' : 'mdi-star-outline'"/>
            </v-btn>
          </template>
          <span>{{ isTenantDefault ? 'Unset tenant as default' : 'Set tenant as default' }}</span>
        </v-tooltip>
    </template>
  </FilterSelect>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import FilterSelect from '@/components/utils/filter-select/FilterSelect.vue';
import { EventLog } from '@/lib/event-log';

export default {
  name: "TenantSelect",
  components: {
    FilterSelect,
  },
  data() {
    return {
      tenantUpdateRequested: false,
    };
  },
  computed: {
    disableDropdown() {
      return this.tenants.length <= 1;
    },
    isTenantDefault() {
      if (this.defaultTenant) {
        return this.tenant.id === this.defaultTenant.id;
      } else {
        return false;
      }
    },
    tenantOptions() {
      const options = this.tenants.map((t) => {
        return { value: t.id, label: t.display_name }
      })
      return options
    },
    ...mapGetters([
      'defaultTenant',
      'tenant',
      'tenants',
      'user',
    ]),
  },
  watch: {
    '$route.query': {
      handler() {
        const query = { ...this.$route.query };
        if ('tenant' in query) {
          const tenantName = query.tenant;
          const validTenant = this.tenants.find((tenant) => tenant.name === tenantName);
          if (validTenant) {
            this.changeTenant();
          }
          delete query.tenant;
          if (!objectsEqual(this.$route.query, query)) {
            this.$router.push({ query });
          }
        }
      },
    },
  },
  methods: {
    changeTenant(t) {
      if (t.value !== this.tenant.id) {
        const tenant = this.tenants.find((ten) => ten.id === t.value);
        const options = {
          tenant,
          resetRequests: true,
          withClaims: true,
          initialLoad: true,
        };
        this.updateTenant(options).then(() => {
          const loggingData = new EventLog({
            event: 'account.change_tenant',
            oldTenant: this.tenant.name,
            newTenant: tenant.name,
          });
          this.$services.users.postTrackingLog(loggingData);
          const now = moment();
          const { params } = this.$route;
          const menuTitle = this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_');
          const menuName = (params && params.id) ? `${menuTitle}.${params.id.replace(/ |-/g, '_')}` : menuTitle;
          const menuData = {
            timestamp: now,
            menu: menuName,
          };
          this.setMenuNavigationStart(menuData);
        }).catch((error) => {
          this.$notify('Failed to set tenant.');
          const loggingData = new EventLog({
            event: 'account.fail_change_tenant',
            oldTenant: this.tenant.name,
            newTenant: tenant.name,
            error: error.message,
          });
          this.$services.users.postTrackingLog(loggingData);
        });
      }
    },
    updateDefaultTenant() {
      this.tenantUpdateRequested = true;
      if (!this.defaultTenant) {
        this.$services.users.postDefaultUserTenant(this.user.id, this.tenant.id).then(() => {
          this.setDefaultTenant(this.tenant);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.set_default_tenant',
            default_tenant: this.tenant.name,
          });
          this.$services.users.postTrackingLog(loggingData);
        }).catch((error) => {
          this.$notify(error);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.fail_set_default_tenant',
            default_tenant: this.tenant.name,
            error: error.message,
          });
          this.$services.users.postTrackingLog(loggingData);
        });
      } else if (this.isTenantDefault) {
        this.$services.users.deleteDefaultUserTenant(this.user.id, this.tenant.id).then(() => {
          this.setDefaultTenant(null);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.unset_default_tenant',
            default_tenant: this.tenant.name,
          });
          this.$services.users.postTrackingLog(loggingData);
        }).catch((error) => {
          this.$notify(error);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.fail_unset_default_tenant',
            default_tenant: this.tenant.name,
            error: error.message,
          });
          this.$services.users.postTrackingLog(loggingData);
        });
      } else {
        this.$services.users.putDefaultUserTenant(this.user.id, this.tenant.id).then(() => {
          this.setDefaultTenant(this.tenant);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.set_default_tenant',
            default_tenant: this.tenant.name,
          });
          this.$services.users.postTrackingLog(loggingData);
        }).catch((error) => {
          this.$notify(error);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.fail_set_default_tenant',
            default_tenant: this.tenant.name,
            error: error.message,
          });
          this.$services.users.postTrackingLog(loggingData);
        });
      }
    },
    ...mapActions([
      'setDefaultTenant',
      'setMenuNavigationStart',
      'updateTenant',
    ]),
  }
};
</script>

<style lang="scss">

.favorite-icon {
  margin-top: 0rem;
  padding-bottom: 2px;
  color: black;
}
</style>
