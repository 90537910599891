<template>
  <div class="dropdown" ref="dropdown">
    <div
      v-if="!isOpen"
      ref="dropdownSelected"
      class="dropdown-selected"
      @click="toggleDropdown"
    >
      {{ selectedOption }}
      <v-icon v-if="!disableDropdown" icon="mdi-menu-down" />
    </div>
    <div class="input-wrapper" v-else>
      <input
        ref="filterInput"
        class="filter-input"
        :style="{ width: inputWidth + 'px' }"
        :placeholder="selectedOption"
        v-model="filter"
        @keydown.native.enter="validateInput(filter)"
      />
      <slot name="secondary-action"></slot>
    </div>
  </div>
  <Teleport to="#app">
    <div
      v-if="isOpen"
      ref="dropdownMenu"
      class="dropdown-menu"
      :class="{ 'dropdown-up': isUp }"
      :style="{
        top: top + 'px',
        left: left + 'px',
        visibility: left ? 'visible' : 'hidden',
      }"
    >
      <ul>
        <li
          v-for="(option, index) in filteredOptions"
          tabindex="0"
          ref="optionItems"
          :key="index"
          class="dropdown-item"
          @keydown.native.enter="selectOption(option)"
          @click="selectOption(option)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: "FilterSelect",
  emits: ["selectChanged"],
  props: {
    disableDropdown: {
      type: Boolean,
      default: () => false,
    },
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filter: "",
      isOpen: false,
      isUp: false,
      localSelectedOption: null,
      inputWidth: 0,
      top: 0,
      right: 0,
      left: 0,
      navigator: -1,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((x) =>
        x.label.toLowerCase().startsWith(this.filter.toLowerCase())
      );
    },
  },
  watch: {
    selectedOption(old, curr) {
      if (old !== curr) {
        this.localSelectedOption = curr;
      }
    },
    inputWidth(newVal) {
      if (newVal) {
      }
    },
    isOpen(newVal) {
      if (newVal) {
        document.addEventListener("mousedown", this.handleClickAway);
        document.addEventListener("keydown", this.handleKeyDown);
        this.$nextTick(() => {
          setTimeout(() => {
            const dropdownMenu = this.$refs.dropdownMenu.getBoundingClientRect();
            this.inputWidth = dropdownMenu.width - 10;
            this.updateLeft();            
          }, 0);
        });
      } else {
        document.removeEventListener("mousedown", this.handleClickAway);
        document.removeEventListener("keydown", this.handleKeyDown);
      }
    },
  },
  methods: {
    updateLeft() {
      this.$nextTick(() => {
        setTimeout(() => {
          const rect = this.$refs.dropdown.getBoundingClientRect();
          this.left = rect.x;
        }, 0);
      });
    },
    toggleDropdown() {
      if (!this.disableDropdown) {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          this.adjustDropdownDirection();
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.filterInput.focus();
            }, 0);
          });
        }
      }
    },
    validateInput(input) {
      const option = this.options.find(
        (option) => option.label.toLowerCase() === input.toLowerCase()
      );
      if (option) this.selectOption(option);
    },
    selectOption(option) {
      if (option.value !== this.selectedOption.value) {
        this.$emit("selectChanged", option);
        this.isOpen = false;
      }
    },
    handleKeyDown(event) {
      if (event.key === "ArrowDown") {
        this.navigateNext();
      }
      if (event.key === "ArrowUp") {
        this.navigatePrev();
      }
    },
    navigatePrev() {
      this.$nextTick(() => {
        if (this.$refs.optionItems?.length) {
          if (this.navigator === 0) {
            this.navigator--;
            this.$refs.filterInput.focus();
            this.moveCursorToEnd({ target: this.$refs.filterInput });
          } else if (this.navigator > 0) {
            this.navigator--;
            this.$refs.optionItems[this.navigator].focus();
          }
        }
      });
    },
    navigateNext() {
      this.$nextTick(() => {
        if (this.$refs.optionItems?.length > this.navigator + 1) {
          this.navigator++;
          this.$refs.optionItems[this.navigator].focus();
        }
      });
    },
    moveCursorToEnd(event) {
      this.$nextTick(() => {
        setTimeout(() => {
          const input = event.target;
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      });
    },
    adjustDropdownDirection() {
      this.$nextTick(() => {
        const rect = this.$refs.dropdown.getBoundingClientRect();
        this.top = rect.bottom;

        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;
        this.isUp = spaceBelow < 200 && spaceAbove > spaceBelow; // Opens upward if there's more space above
      });
    },
    handleClickAway(event) {
      if (this.isOpen) {
        const dropdown = this.$refs.dropdown;
        const dropdownMenu = this.$refs.dropdownMenu;
        if (
          dropdown &&
          !dropdown.contains(event.target) &&
          dropdownMenu &&
          !dropdownMenu.contains(event.target)
        ) {
          this.isOpen = false;
          this.navigator = -1;
          this.filter = "";
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/main.scss";
@import "@/sass/icons.scss";

.input-wrapper {
  max-height: 32px;
  border-radius: 6px;
  padding: 4px;
  background: white;
  outline: none;
}

.filter-input {
  margin-left: 4px;
  outline: none;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-selected {
  display: flex;
  text-align: end;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  max-height: 200px;
  min-width: 178px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1005;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
}

.dropdown-up {
  top: auto;
  bottom: 100%;
  transform: translateY(-5px);
}

.dropdown-item {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  cursor: pointer;

  &:focus {
    background-color: darken($bainbridge-gray-light, 5);
    border: 1px solid darken($bainbridge-gray-light, 55);
    border-radius: 4px;
    outline: none;
  }
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
</style>
